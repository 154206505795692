﻿.signup-page {
    background-color: #00BCD4;
    padding-left: 0;
    max-width: 360px;
    margin: 5% auto;
    overflow-x: hidden;

    .signup-box {
        .msg {
            color: #555;
            margin-bottom: 30px;
            text-align: center;
        }

        a {
            font-size: 14px;
            text-decoration: none;
            color: #00BCD4;
        }

        .logo {
            a {
                font-size: 36px;
                display: block;
                width: 100%;
                text-align: center;
                color: #fff;
            }

            small {
                display: block;
                width: 100%;
                text-align: center;
                color: #fff;
                margin-top: -5px;
            }

            margin-bottom: 20px;
        }
    }
}
