﻿.dtp {
  div.dtp-date,
  div.dtp-time {
    background: #007d72;
  }

  > .dtp-content {
    > .dtp-date-view {
      > header.dtp-header {
        background: #009688;
      }
    }
  }

  .dtp-buttons {
    .dtp-btn-ok {
      margin-left: 10px;
    }

    .dtp-btn-clear {
      margin-right: 10px !important;
    }
  }

  .p10 {
    > a {
      color: #fff;
    }
  }

  div.dtp-actual-year {
    font-size: 1.5em;
    color: #ffffff;
  }

  table.dtp-picker-days {
    tr {
      td {
        a.selected {
          background: #007d72;
          color: #fff;
        }
      }
    }
  }
}

.datepicker {
  &.datepicker-dropdown {
    &.dropdown-menu {
      margin-top: 0 !important;
    }
  }

  table {
    tr {
      td {
        &.active {
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from($datepicker-active-color), to($datepicker-active-color));
          background-image: -webkit-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
          background-image: -o-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
          background-image: linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
          border: none;

          &:hover {
            &.active {
              background-image: -webkit-gradient(linear, 0 0, 0 100%, from($datepicker-active-color), to($datepicker-active-color));
              background-image: -webkit-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
              background-image: -o-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
              background-image: linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
              border: none;
            }
          }
        }

        &.selected {
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from($datepicker-active-color), to($datepicker-active-color));
          background-image: -webkit-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
          background-image: -o-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
          background-image: linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
          border: none;
        }

        span {
          &.active {
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from($datepicker-active-color), to($datepicker-active-color));
            background-image: -webkit-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
            background-image: -o-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
            background-image: linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
            border: none;

            &:hover {
              &.active {
                background-image: -webkit-gradient(linear, 0 0, 0 100%, from($datepicker-active-color), to($datepicker-active-color));
                background-image: -webkit-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
                background-image: -o-linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
                background-image: linear-gradient(to bottom, $datepicker-active-color, $datepicker-active-color);
                border: none;
              }
            }
          }
        }
      }
    }

    &.table-condensed {
      > tbody {
        > tr {
          > td {
            padding: 6px 9px;
          }
        }
      }
    }
  }
}

.input-daterange {
  .form-control {
    text-align: left;
  }

  .input-group-addon {
    padding-right: 10px !important;
  }
}

.input-clear {
  display: inline-flex;
  width: 100%;

  .icon-content {
    cursor: pointer;
    margin-top: 10px;
    color: $beepark-black;

    .icon-close {
      font-weight: 700;
      font-size: 1.35rem;
      color: $beepark-darkgray;
    }
  }
}
