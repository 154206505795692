.exploitation-form-tabs {

  .tab {
    border-radius: 5px;
    padding: 10px;
    background-color: $beepark-background;
    font-weight: bolder;
    border-bottom: 1px solid #ccc;
    height: 60px;
    font-size: 13px;

    &.selected {
      color: $beepark;
      background-color: map-get($colors, white);
    }

    p {
      margin-left: 5px;
    }

    // Flexbox
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .tab:hover {
    cursor: pointer;
    background-color: map-get($colors, white);
  }

  .material-icons {

    &.completed {
      color: map-get($colors, green);
    }

    &.uncompleted {
      color: map-get($colors, amber)
    }
  }
}