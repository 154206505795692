.breadcrumb {
    @include border-radius(0);
    background-color: transparent;
    font-size: 13px;
    margin-bottom: 10px;

    li {
        a {
            color: $beepark-gray;
            text-decoration: none;
						&:hover {
							color: $beepark;
						}

            .material-icons {
                font-size: 18px;
                position: relative;
                top: 4px;
            }
        }

        .material-icons {
            font-size: 18px;
            position: relative;
            top: 4px;
        }
    }

    > li + li:before {
        content: '>\00a0';
    }
}

@each $key, $val in $colors {
    .breadcrumb-col-#{$key} {
        li {
            a {
                color: $val !important;
                font-weight: bold;
            }
        }
    }

    .breadcrumb-bg-#{$key} {
        background-color: $val !important;

        li {
            a {
                color: #fff;
                font-weight: bold;

                .material-icons {
                    padding-bottom: 8px;
                }
            }

            color: #fff !important;
        }

        li + li:before {
            color: #fff;
        }
    }
}
