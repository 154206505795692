body {
    background-color: $beepark-background;
    @include transition(all .15s);
    font-family: 'Raleway','Roboto', Arial, Tahoma, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

button,
input,
select,
a {
    outline: none !important;
}

.no-animate {
    -webkit-transition-property: none !important;
    transition-property: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-animation: none !important;
    animation: none !important;
}

section {
    &.content {
        margin: 100px 15px 0 15px;
        @include transition(.5s);
    }
}
