.card {
	-webkit-box-shadow: 0 2px 14px rgba($beepark-black, .2);
	background: #fff;
	box-shadow: 0 2px 14px rgba($beepark-black, .2);
	margin-bottom: 30px;
	min-height: 50px;
	position: relative;
	@include border-radius(10px);

	.card-inside-title {
		color: #000;
		display: block;
		font-size: 15px;
		margin-bottom: 15px;
		margin-top: 25px;

		small {
			color: #999;
			display: block;
			font-size: 11px;
			margin-top: 5px;

			a {
				color: #777;
				font-weight: bold;
			}
		}
	}

	.card-inside-title:first-child {
		margin-top: 0;
	}

	.bg-red,
	.bg-pink,
	.bg-purple,
	.bg-deep-purple,
	.bg-indigo,
	.bg-blue,
	.bg-light-blue,
	.bg-cyan,
	.bg-teal,
	.bg-green,
	.bg-light-green,
	.bg-lime,
	.bg-yellow,
	.bg-amber,
	.bg-orange,
	.bg-deep-orange,
	.bg-brown,
	.bg-grey,
	.bg-blue-grey,
	.bg-black {
		border-bottom: none !important;
		color: #fff !important;

		h2, small, .material-icons {
			color: #fff !important;
		}

		.badge {
			background-color: #fff;
			color: #555;
		}
	}

	.header {
		border-bottom: 1px solid rgba(204,204,204,0.35);
		color: #555;
		padding: 20px;
		position: relative;


		.header-dropdown {
			list-style: none;
			position: absolute;
			right: 15px;
			top: 20px;

			.dropdown-menu {
				li {
					display: block !important;
				}
			}

			li {
				display: inline-block;
			}

			i {
				color: #999;
				font-size: 20px;
				@include transition(all .5s);

				&:hover {
					color: #000;
				}
			}
		}

		h2 {
			color: $beepark;
			font-size: 16px;
			font-weight: bolder;
			margin: 0;

			small {
				color: $beepark-gray;
				display: block;
				font-size: 12px;
				line-height: 15px;
				margin-top: 5px;

				a {
					color: $beepark;
					font-weight: bold;
				}
			}
		}

		.col-xs-12 {
			h2 {
				margin-top: 5px;
			}
		}
	}

	.body {
		color: #555;
		font-size: 14px;
		padding: 20px;

		@for $i from 1 through 12 {
			.col-xs-#{$i},
			.col-sm-#{$i},
			.col-md-#{$i},
			.col-lg-#{$i} {
				margin-bottom: 20px;
			}
		}
	}

	.resolved-alert {
		-webkit-transition-duration: .12s;
		background-color: transparent;
		border-bottom: 1px solid rgba($beepark-gray,.25);
		padding: 2rem 1rem;
		transition-duration: .12s;
		&:hover {
			background-color: rgba($beepark,.15);
		}
	}

	.unresolved-alert {
		-webkit-transition-duration: .12s;
		background-color: rgba($beepark,.1);
		border-bottom: 1px solid rgba($beepark-gray,.25);
		padding: 2rem 1rem;
		transition-duration: .12s;
		&:hover {
			background-color: rgba($beepark,.15);
		}
	}

	.title-alert {
		color: $beepark-darkgray;
	}

	.time-alert {
		// font-weight: bolder;
		color: rgba($beepark-darkgray,.8);
		font-size: 1.1rem;
		letter-spacing: .05rem;
		margin-bottom: 2rem;
		i {
			font-size: 1.2rem;
			margin-right: 1rem;
		}
	}

}

.card-search-filter {
	-ms-flex-pack: end;
	-webkit-box-pack: end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	.input-group {
		margin-bottom: 0;
		margin-right: 5px;
	}
	.header-dropdown {
		top:9px !important;
	}
	.btn-group {
		-webkit-box-shadow: none;
		box-shadow: none;
		vertical-align: initial;
	}
	.header-item {
		margin-left: 10px;
		i {
			color: $beepark-darkgray;
			font-size: 20px;
			margin-top: 8px;
		}
	}

	a {
		-webkit-transition-duration: .2s;
		cursor: pointer;
		transition-duration: .2s;
		&:hover {
			-webkit-transition-duration: .2s;
			transition-duration: .2s;
			i {
				color: $beepark;
			}
		}
	}
}

.display-none {
	display: none;
}

.card-collapsed-error {
	border-style: solid;
	border-width: 2px;
	border-color: $danger !important;
}

.title-cursor {
	cursor: pointer;
	&:hover {
		color: lighten($beepark, 10%) !important;
	}
}

.empty-msg-cnt {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.empty-msg-icon {
	margin-top: -20px;
	color: $beepark;
	font-size: 36px;
}

.card-filters {
	margin-top: 15px;
	padding: 25px;
}