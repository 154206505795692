﻿.dashboard-flot-chart {
    height: 275px;
}

.dashboard-donut-chart {
    height: 400px;
    padding: 0px !important;
}

.dashboard-item {
    .iframe {
        height: 400px;
        padding: 0px !important;
    }
    h3 {
        color: $beepark-gray;
        font-size: 14px;
        font-weight: bolder;
    }
}

.dashboard-line-chart {
    height: 250px;
}

.dashboard-stat-list {
    list-style: none;
    padding-left: 0;
    margin-top: 40px;

    li {
        padding: 16px 0 0 0;

        small {
            font-size: 8px;
        }
    }
}

.dashboard-task-infos {
    .progress {
        height: 10px;
        margin-bottom: 0;
        position: relative;
        top: 6px;
    }
}

iframe {
    border: none;
}

.printable {
    display: none;
}

@media print {
    .printable {
        display: block;
    }
}


.pin{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}