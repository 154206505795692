label {
    color: $beepark-darkgray;
    font-weight: 300;
}

.label {
    border-radius: 3px;
    padding: .2em .6em .2em;
}

.label-primary {
    background-color: $beepark;
}

.label-success {
    background-color: $success;
}

.label-info {
    background-color: $info;
}

.label-warning {
    background-color: $warning;
}

.label-danger {
    background-color: $danger;
}

.label-pendiente {
    background-color: #00BCD4;
}
.label-valido {
    background-color: $success;
}
.label-rechazado {
    background-color: $danger;
}
.label-caducado {
    background-color: $warning;
}
.label-up-to-date {
    background-color: #009688;
}

.label-error {
    color: $danger !important;
    display: block;
    font-size: 12px !important;
    font-weight: normal !important;
    margin-top: 5px;
}

