.modal {
	.modal-header {
		border: none;
		padding: 25px 25px 5px 25px;

		.modal-title {
			color: $beepark;
			font-size: 16px;
			font-weight: bold;
		}
	}

	.modal-content {
		@include border-radius(0);
		-webkit-box-shadow: 0 5px 20px rgba($beepark-darkgray,.31) !important;
		border: none;
		border-radius: 10px;
		box-shadow: 0 5px 20px rgba($beepark-darkgray,.31) !important;

		.modal-body {
			color: $beepark-darkgray;
			padding: 15px 25px;
		}
	}

	.modal-footer {
		border: none;
	}
}

@each $key, $val in $colors {
	.modal-col-#{$key} {
		background-color: $val;

		.modal-body,
		.modal-title {
			color: #fff !important;
		}

		.modal-footer {
			background-color: rgba(0,0,0,0.12);

			.btn-link {
				color: #fff !important;

				&:hover,
				&:active,
				&:focus {
					background-color: rgba(0,0,0,0.12);
				}
			}
		}
	}
}

.modal-backdrop.in {
	// filter:alpha(opacity=100);
	opacity:.7;
	background-color: $beepark-gray;
}

.layout-images {
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: 1fr 1fr 1fr;
	img {
		max-width: 160px;
	}

}