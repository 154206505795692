.input-group {
  width: 100%;
}

.input-group-search {
  margin-top: 3px;
  width: 75%;
}

.input-group, .input-group-search {
  display: inline-table;
  margin-bottom: 20px;

  .form-line {
    border-bottom: 1px solid #ddd;
    display: inline-block;
    position: relative;
    width: 100%;

    &:after {
      border-bottom: 2px solid #1f91f3;
      bottom: -2px;
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
      @include transform(scaleX(0));
      @include transition(0.25s ease-in);
    }

    + .input-group-addon {
      padding-left: 10px;
      padding-right: 0;
    }

  }

  .form-line-icon {
    display: flex;
    padding: 0 1rem;
  }

  .help-info {
    color: #999;
    float: right;
    font-size: 12px;
    margin-top: 5px;
  }

  label.error {
    color: $danger;
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-top: 5px;
  }

  .form-line.error {
    &:after {
      border-bottom: 2px solid $danger;
    }
  }

  .form-line.success {
    &:after {
      border-bottom: 2px solid #4CAF50;
    }
  }

  .form-line.warning {
    &:after {
      border-bottom: 2px solid #FFC107;
    }
  }

  .form-line.focused {
    &:after {
      @include transform(scaleX(1));
    }

    .form-label {
      bottom: 25px;
      font-size: 12px;
      left: 0;
    }
  }

  .input-group-addon {
    background-color: transparent;
    border: none;
    font-weight: bold;
    padding-left: 0;

    .material-icons {
      color: #555;
      font-size: 18px;
    }
  }

  input[type="text"],
  .form-control {
    -webkit-box-shadow: none;
    border: none;
    box-shadow: none;
    padding-left: 0;
  }

  .form-control {
    &:focus {
      @include box-shadow(none !important);
    }
  }
}


.input-group.input-group-sm {
  .input-group-addon {
    i {
      font-size: 14px;
    }
  }

  .form-control {
    font-size: 12px;
  }
}

.input-group.input-group-lg {

  .input-group-addon {
    i {
      font-size: 26px;
    }
  }

  .form-control {
    font-size: 18px;
  }
}

.form-control-label {
  text-align: right;

  label {
    margin-top: 8px;
  }
}

.form-horizontal {
  .form-group {
    margin-bottom: 0;
  }
}

.form-group {
  //margin-bottom: 25px;
  margin-top: 15px;
  width: 100%;

  .form-control {
    -webkit-box-shadow: none;
    border: none;
    box-shadow: none;
    width: 100%;
    // border-bottom: 1px solid #ddd;
    @include border-radius(0);
    padding-left: 0;
  }

  .help-info {
    color: #999;
    float: right;
    font-size: 12px;
    margin-top: 5px;
  }

  .form-line {
    border-bottom: 1px solid #ddd;
    position: relative;
    width: 100%;

    &:after {
      border-bottom: 2px solid #1f91f3;
      bottom: -1px;
      content: '';
      height: 0;
      left: 0;
      position: absolute;
      width: 100%;
      @include transform(scaleX(0));
      @include transition(0.25s ease-in);
    }

    .form-label {
      color: #aaa;
      cursor: text;
      font-weight: normal;
      left: 0;
      position: absolute;
      top: 10px;
      @include transition(0.2s);
    }

  }

  .form-line.error {
    &:after {
      border-bottom: 2px solid $danger;
    }
  }

  .form-line.success {
    &:after {
      border-bottom: 2px solid #4CAF50;
    }
  }

  .form-line.warning {
    &:after {
      border-bottom: 2px solid #FFC107;
    }
  }

  .form-line.focused {
    &:after {
      @include transform(scaleX(1));
    }

    .form-label {
      font-size: 12px;
      left: 0;
      top: -10px;
    }
  }
}

.form-group-sm {
  .form-label {
    font-size: 8px;
  }

  .form-line.focused {
    .form-label {
      bottom: 20px;
      font-size: 10px;
    }
  }
}

.form-group-lg {
  .form-label {
    font-size: 18px;
  }

  .form-line.focused {
    .form-label {
      bottom: 35px;
      font-size: 12px;
    }
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control, .form-control {
  background-color: transparent;
}

.form-control select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.form-line,
.form-group,
.row {

  label {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .5px;
    color: $beepark-gray;

    label.obligatory {
      font-weight: bolder;
      color: map-get($colors, red)
    }
  }

}

.question {
  label.main-label {
    font-size: 1.5rem;
  }
  label.label-checkbox {
    font-size: 14px;
    font-weight: 300;
    color: #555;
  }
}

.question div.mdc-form-field > label {
  font-size: 14px;
  font-weight: 300;
  color: #555;
  font-family: 'Raleway','Roboto', Arial, Tahoma, sans-serif;
}

label.error {
  color: $danger;
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin-top: 5px;
}