﻿.progress {
    @include border-radius(0);
    height: 22px;

    .progress-bar {
        line-height: 23px;
        background-color: $beepark;
    }

    .progress-bar-success {
        background-color: $success;
    }

    .progress-bar-info {
        background-color: #00b0e4;
    }

    .progress-bar-warning {
        background-color: $warning;
    }

    .progress-bar-danger {
        background-color: $danger;
    }
}
