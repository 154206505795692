﻿//Theme Red
$theme-red: #F44336;
$theme-red-legal-bg: #fff;
$theme-red-bar: #fff;
$theme-red-navbar-brand: #fff;
$theme-red-navbar-brand_active: #fff;
$theme-red-navbar-brand_hover: #fff;
$theme-red-navbar-brand_focus: #fff;
$theme-red-nav-anchor: #fff;
$theme-red-nav-anchor_hover: rgba(0,0,0,0);
$theme-red-nav-anchor_focus: rgba(0,0,0,0);
$theme-red-nav-anchor-opened: rgba(0,0,0,0);
$theme-red-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-red-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-red-menu-list-active: rgba(0,0,0,0);
$theme-red-menu-list-toggled: rgba(0,0,0,0);
$theme-red-menu-list-submenu: rgba(0,0,0,0);

//Theme Pink
$theme-pink: #E91E63;
$theme-pink-legal-bg: #fff;
$theme-pink-bar: #fff;
$theme-pink-navbar-brand: #fff;
$theme-pink-navbar-brand_active: #fff;
$theme-pink-navbar-brand_hover: #fff;
$theme-pink-navbar-brand_focus: #fff;
$theme-pink-nav-anchor: #fff;
$theme-pink-nav-anchor_hover: rgba(0,0,0,0);
$theme-pink-nav-anchor_focus: rgba(0,0,0,0);
$theme-pink-nav-anchor-opened: rgba(0,0,0,0);
$theme-pink-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-pink-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-pink-menu-list-active: rgba(0,0,0,0);
$theme-pink-menu-list-toggled: rgba(0,0,0,0);
$theme-pink-menu-list-submenu: rgba(0,0,0,0);

//Theme Purple
$theme-purple: #9C27B0;
$theme-purple-legal-bg: #fff;
$theme-purple-bar: #fff;
$theme-purple-navbar-brand: #fff;
$theme-purple-navbar-brand_active: #fff;
$theme-purple-navbar-brand_hover: #fff;
$theme-purple-navbar-brand_focus: #fff;
$theme-purple-nav-anchor: #fff;
$theme-purple-nav-anchor_hover: rgba(0,0,0,0);
$theme-purple-nav-anchor_focus: rgba(0,0,0,0);
$theme-purple-nav-anchor-opened: rgba(0,0,0,0);
$theme-purple-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-purple-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-purple-menu-list-active: rgba(0,0,0,0);
$theme-purple-menu-list-toggled: rgba(0,0,0,0);
$theme-purple-menu-list-submenu: rgba(0,0,0,0);

//Theme Deep Purple
$theme-deep-purple: #673AB7;
$theme-deep-purple-legal-bg: #fff;
$theme-deep-purple-bar: #fff;
$theme-deep-purple-navbar-brand: #fff;
$theme-deep-purple-navbar-brand_active: #fff;
$theme-deep-purple-navbar-brand_hover: #fff;
$theme-deep-purple-navbar-brand_focus: #fff;
$theme-deep-purple-nav-anchor: #fff;
$theme-deep-purple-nav-anchor_hover: rgba(0,0,0,0);
$theme-deep-purple-nav-anchor_focus: rgba(0,0,0,0);
$theme-deep-purple-nav-anchor-opened: rgba(0,0,0,0);
$theme-deep-purple-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-deep-purple-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-deep-purple-menu-list-active: rgba(0,0,0,0);
$theme-deep-purple-menu-list-toggled: rgba(0,0,0,0);
$theme-deep-purple-menu-list-submenu: rgba(0,0,0,0);

//Theme Indigo
$theme-indigo: #3F51B5;
$theme-indigo-legal-bg: #fff;
$theme-indigo-bar: #fff;
$theme-indigo-navbar-brand: #fff;
$theme-indigo-navbar-brand_active: #fff;
$theme-indigo-navbar-brand_hover: #fff;
$theme-indigo-navbar-brand_focus: #fff;
$theme-indigo-nav-anchor: #fff;
$theme-indigo-nav-anchor_hover: rgba(0,0,0,0);
$theme-indigo-nav-anchor_focus: rgba(0,0,0,0);
$theme-indigo-nav-anchor-opened: rgba(0,0,0,0);
$theme-indigo-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-indigo-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-indigo-menu-list-active: rgba(0,0,0,0);
$theme-indigo-menu-list-toggled: rgba(0,0,0,0);
$theme-indigo-menu-list-submenu: rgba(0,0,0,0);

//Theme Blue
$theme-blue: #2196F3;
$theme-blue-legal-bg: #fff;
$theme-blue-bar: #fff;
$theme-blue-navbar-brand: #fff;
$theme-blue-navbar-brand_active: #fff;
$theme-blue-navbar-brand_hover: #fff;
$theme-blue-navbar-brand_focus: #fff;
$theme-blue-nav-anchor: #fff;
$theme-blue-nav-anchor_hover: rgba(0,0,0,0);
$theme-blue-nav-anchor_focus: rgba(0,0,0,0);
$theme-blue-nav-anchor-opened: rgba(0,0,0,0);
$theme-blue-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-blue-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-blue-menu-list-active: rgba(0,0,0,0);
$theme-blue-menu-list-toggled: rgba(0,0,0,0);
$theme-blue-menu-list-submenu: rgba(0,0,0,0);

//Theme Light Blue
$theme-light-blue: #03A9F4;
$theme-light-blue-legal-bg: #fff;
$theme-light-blue-bar: #fff;
$theme-light-blue-navbar-brand: #fff;
$theme-light-blue-navbar-brand_active: #fff;
$theme-light-blue-navbar-brand_hover: #fff;
$theme-light-blue-navbar-brand_focus: #fff;
$theme-light-blue-nav-anchor: #fff;
$theme-light-blue-nav-anchor_hover: rgba(0,0,0,0);
$theme-light-blue-nav-anchor_focus: rgba(0,0,0,0);
$theme-light-blue-nav-anchor-opened: rgba(0,0,0,0);
$theme-light-blue-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-light-blue-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-light-blue-menu-list-active: rgba(0,0,0,0);
$theme-light-blue-menu-list-toggled: rgba(0,0,0,0);
$theme-light-blue-menu-list-submenu: rgba(0,0,0,0);

//Theme Cyan
$theme-cyan: #00BCD4;
$theme-cyan-legal-bg: #fff;
$theme-cyan-bar: #fff;
$theme-cyan-navbar-brand: #fff;
$theme-cyan-navbar-brand_active: #fff;
$theme-cyan-navbar-brand_hover: #fff;
$theme-cyan-navbar-brand_focus: #fff;
$theme-cyan-nav-anchor: #fff;
$theme-cyan-nav-anchor_hover: rgba(0,0,0,0);
$theme-cyan-nav-anchor_focus: rgba(0,0,0,0);
$theme-cyan-nav-anchor-opened: rgba(0,0,0,0);
$theme-cyan-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-cyan-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-cyan-menu-list-active: rgba(0,0,0,0);
$theme-cyan-menu-list-toggled: rgba(0,0,0,0);
$theme-cyan-menu-list-submenu: rgba(0,0,0,0);

//Theme Teal
$theme-teal: #009688;
$theme-teal-legal-bg: #fff;
$theme-teal-bar: #fff;
$theme-teal-navbar-brand: #fff;
$theme-teal-navbar-brand_active: #fff;
$theme-teal-navbar-brand_hover: #fff;
$theme-teal-navbar-brand_focus: #fff;
$theme-teal-nav-anchor: #fff;
$theme-teal-nav-anchor_hover: rgba(0,0,0,0);
$theme-teal-nav-anchor_focus: rgba(0,0,0,0);
$theme-teal-nav-anchor-opened: rgba(0,0,0,0);
$theme-teal-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-teal-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-teal-menu-list-active: rgba(0,0,0,0);
$theme-teal-menu-list-toggled: rgba(0,0,0,0);
$theme-teal-menu-list-submenu: rgba(0,0,0,0);

//Theme Green
$theme-green: #4CAF50;
$theme-green-legal-bg: #fff;
$theme-green-bar: #fff;
$theme-green-navbar-brand: #fff;
$theme-green-navbar-brand_active: #fff;
$theme-green-navbar-brand_hover: #fff;
$theme-green-navbar-brand_focus: #fff;
$theme-green-nav-anchor: #fff;
$theme-green-nav-anchor_hover: rgba(0,0,0,0);
$theme-green-nav-anchor_focus: rgba(0,0,0,0);
$theme-green-nav-anchor-opened: rgba(0,0,0,0);
$theme-green-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-green-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-green-menu-list-active: rgba(0,0,0,0);
$theme-green-menu-list-toggled: rgba(0,0,0,0);
$theme-green-menu-list-submenu: rgba(0,0,0,0);

//Theme Light Green
$theme-light-green: #8BC34A;
$theme-light-green-legal-bg: #fff;
$theme-light-green-bar: #fff;
$theme-light-green-navbar-brand: #fff;
$theme-light-green-navbar-brand_active: #fff;
$theme-light-green-navbar-brand_hover: #fff;
$theme-light-green-navbar-brand_focus: #fff;
$theme-light-green-nav-anchor: #fff;
$theme-light-green-nav-anchor_hover: rgba(0,0,0,0);
$theme-light-green-nav-anchor_focus: rgba(0,0,0,0);
$theme-light-green-nav-anchor-opened: rgba(0,0,0,0);
$theme-light-green-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-light-green-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-light-green-menu-list-active: rgba(0,0,0,0);
$theme-light-green-menu-list-toggled: rgba(0,0,0,0);
$theme-light-green-menu-list-submenu: rgba(0,0,0,0);

//Theme Lime
$theme-lime: #CDDC39;
$theme-lime-legal-bg: #fff;
$theme-lime-bar: #fff;
$theme-lime-navbar-brand: #fff;
$theme-lime-navbar-brand_active: #fff;
$theme-lime-navbar-brand_hover: #fff;
$theme-lime-navbar-brand_focus: #fff;
$theme-lime-nav-anchor: #fff;
$theme-lime-nav-anchor_hover: rgba(0,0,0,0);
$theme-lime-nav-anchor_focus: rgba(0,0,0,0);
$theme-lime-nav-anchor-opened: rgba(0,0,0,0);
$theme-lime-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-lime-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-lime-menu-list-active: rgba(0,0,0,0);
$theme-lime-menu-list-toggled: rgba(0,0,0,0);
$theme-lime-menu-list-submenu: rgba(0,0,0,0);

//Theme Yellow
$theme-yellow: #FFEB3B;
$theme-yellow-legal-bg: #fff;
$theme-yellow-bar: #fff;
$theme-yellow-navbar-brand: #fff;
$theme-yellow-navbar-brand_active: #fff;
$theme-yellow-navbar-brand_hover: #fff;
$theme-yellow-navbar-brand_focus: #fff;
$theme-yellow-nav-anchor: #fff;
$theme-yellow-nav-anchor_hover: rgba(0,0,0,0);
$theme-yellow-nav-anchor_focus: rgba(0,0,0,0);
$theme-yellow-nav-anchor-opened: rgba(0,0,0,0);
$theme-yellow-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-yellow-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-yellow-menu-list-active: rgba(0,0,0,0);
$theme-yellow-menu-list-toggled: rgba(0,0,0,0);
$theme-yellow-menu-list-submenu: rgba(0,0,0,0);

//Theme Amber
$theme-amber: #FFC107;
$theme-amber-legal-bg: #fff;
$theme-amber-bar: #fff;
$theme-amber-navbar-brand: #fff;
$theme-amber-navbar-brand_active: #fff;
$theme-amber-navbar-brand_hover: #fff;
$theme-amber-navbar-brand_focus: #fff;
$theme-amber-nav-anchor: #fff;
$theme-amber-nav-anchor_hover: rgba(0,0,0,0);
$theme-amber-nav-anchor_focus: rgba(0,0,0,0);
$theme-amber-nav-anchor-opened: rgba(0,0,0,0);
$theme-amber-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-amber-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-amber-menu-list-active: rgba(0,0,0,0);
$theme-amber-menu-list-toggled: rgba(0,0,0,0);
$theme-amber-menu-list-submenu: rgba(0,0,0,0);

//Theme Orange
$theme-orange: #FF9800;
$theme-orange-legal-bg: #fff;
$theme-orange-bar: #fff;
$theme-orange-navbar-brand: #fff;
$theme-orange-navbar-brand_active: #fff;
$theme-orange-navbar-brand_hover: #fff;
$theme-orange-navbar-brand_focus: #fff;
$theme-orange-nav-anchor: #fff;
$theme-orange-nav-anchor_hover: rgba(0,0,0,0);
$theme-orange-nav-anchor_focus: rgba(0,0,0,0);
$theme-orange-nav-anchor-opened: rgba(0,0,0,0);
$theme-orange-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-orange-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-orange-menu-list-active: rgba(0,0,0,0);
$theme-orange-menu-list-toggled: rgba(0,0,0,0);
$theme-orange-menu-list-submenu: rgba(0,0,0,0);

//Theme Deep Orange
$theme-deep-orange: #FF5722;
$theme-deep-orange-legal-bg: #fff;
$theme-deep-orange-bar: #fff;
$theme-deep-orange-navbar-brand: #fff;
$theme-deep-orange-navbar-brand_active: #fff;
$theme-deep-orange-navbar-brand_hover: #fff;
$theme-deep-orange-navbar-brand_focus: #fff;
$theme-deep-orange-nav-anchor: #fff;
$theme-deep-orange-nav-anchor_hover: rgba(0,0,0,0);
$theme-deep-orange-nav-anchor_focus: rgba(0,0,0,0);
$theme-deep-orange-nav-anchor-opened: rgba(0,0,0,0);
$theme-deep-orange-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-deep-orange-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-deep-orange-menu-list-active: rgba(0,0,0,0);
$theme-deep-orange-menu-list-toggled: rgba(0,0,0,0);
$theme-deep-orange-menu-list-submenu: rgba(0,0,0,0);

//Theme Brown
$theme-brown: #795548;
$theme-brown-legal-bg: #fff;
$theme-brown-bar: #fff;
$theme-brown-navbar-brand: #fff;
$theme-brown-navbar-brand_active: #fff;
$theme-brown-navbar-brand_hover: #fff;
$theme-brown-navbar-brand_focus: #fff;
$theme-brown-nav-anchor: #fff;
$theme-brown-nav-anchor_hover: rgba(0,0,0,0);
$theme-brown-nav-anchor_focus: rgba(0,0,0,0);
$theme-brown-nav-anchor-opened: rgba(0,0,0,0);
$theme-brown-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-brown-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-brown-menu-list-active: rgba(0,0,0,0);
$theme-brown-menu-list-toggled: rgba(0,0,0,0);
$theme-brown-menu-list-submenu: rgba(0,0,0,0);

//Theme Grey
$theme-grey: #9E9E9E;
$theme-grey-legal-bg: #fff;
$theme-grey-bar: #fff;
$theme-grey-navbar-brand: #fff;
$theme-grey-navbar-brand_active: #fff;
$theme-grey-navbar-brand_hover: #fff;
$theme-grey-navbar-brand_focus: #fff;
$theme-grey-nav-anchor: #fff;
$theme-grey-nav-anchor_hover: rgba(0,0,0,0);
$theme-grey-nav-anchor_focus: rgba(0,0,0,0);
$theme-grey-nav-anchor-opened: rgba(0,0,0,0);
$theme-grey-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-grey-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-grey-menu-list-active: rgba(0,0,0,0);
$theme-grey-menu-list-toggled: rgba(0,0,0,0);
$theme-grey-menu-list-submenu: rgba(0,0,0,0);

//Theme Blue Grey
$theme-blue-grey: #607D8B;
$theme-blue-grey-legal-bg: #fff;
$theme-blue-grey-bar: #fff;
$theme-blue-grey-navbar-brand: #fff;
$theme-blue-grey-navbar-brand_active: #fff;
$theme-blue-grey-navbar-brand_hover: #fff;
$theme-blue-grey-navbar-brand_focus: #fff;
$theme-blue-grey-nav-anchor: #fff;
$theme-blue-grey-nav-anchor_hover: rgba(0,0,0,0);
$theme-blue-grey-nav-anchor_focus: rgba(0,0,0,0);
$theme-blue-grey-nav-anchor-opened: rgba(0,0,0,0);
$theme-blue-grey-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-blue-grey-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-blue-grey-menu-list-active: rgba(0,0,0,0);
$theme-blue-grey-menu-list-toggled: rgba(0,0,0,0);
$theme-blue-grey-menu-list-submenu: rgba(0,0,0,0);

//Theme Black
$theme-black: #000;
$theme-black-legal-bg: #fff;
$theme-black-bar: #fff;
$theme-black-navbar-brand: #fff;
$theme-black-navbar-brand_active: #fff;
$theme-black-navbar-brand_hover: #fff;
$theme-black-navbar-brand_focus: #fff;
$theme-black-nav-anchor: #fff;
$theme-black-nav-anchor_hover: rgba(0,0,0,0);
$theme-black-nav-anchor_focus: rgba(0,0,0,0);
$theme-black-nav-anchor-opened: rgba(0,0,0,0);
$theme-black-nav-anchor-opened_hover: rgba(0,0,0,0);
$theme-black-nav-anchor-opened_focus: rgba(0,0,0,0);
$theme-black-menu-list-active: rgba(0,0,0,0);
$theme-black-menu-list-toggled: rgba(0,0,0,0);
$theme-black-menu-list-submenu: rgba(0,0,0,0);
